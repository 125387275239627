import React from 'react'

const Video = ({ videoSrcURL, videoTitle, className }) => (
    <div className="video">
        <iframe
            src={videoSrcURL}
            title={videoTitle}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            className={className}
        />
    </div>
)

export default Video
