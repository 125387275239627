import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Video from '../../components/Video'

const useStyles = makeStyles((theme) => ({
    highlightTitle: {
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    mainTitle: {
        fontSize: '2.00192rem',
        lineHeight: '1.3',
        fontWeight: 'bold',
    },
    videoContainer: {
        width: '100%',
        position: 'relative',
        paddingBottom: '56.25%', // for 16:9 aspect ratio, adjust accordingly
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}))

const ComponentVideo = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles()

    return (
        <Box py={5}>
            <Container maxWidth="lg">
                <Box py={5}>
                    <Box py={5}>
                        {data.title && (
                            <Typography
                                className={classes.mainTitle}
                                align="center"
                                variant="h1"
                                component="h2"
                            >
                                {data.title}
                            </Typography>
                        )}
                    </Box>
                    <Box pb={3}>
                        {data.description && (
                            <Typography
                                align="center"
                                variant="h6"
                                component="h3"
                                gutterBottom
                            >
                                {data.description}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box className={classes.videoContainer}>
                    {data.videoUrl && (
                        <Video
                            className={classes.video}
                            videoSrcURL={data.videoUrl}
                            videoTitle={data.title}
                        />
                    )}
                </Box>
            </Container>
        </Box>
    )
}

export default ComponentVideo
